import React, { useCallback, useContext, useEffect, useState } from 'react';

import { Container } from './styles';

import FormDefault from '~/components/FormDefault';

import { Produtor } from './screens/Produtor';
import { Financeiro } from './screens/Financeiro';
import { Critica } from './screens/Critica';
import { Badge, Tab, Tabs } from 'react-bootstrap';
import { Guia } from './screens/Guia';
import { Nfe } from './screens/Nfe';

import { useEntradaNFE } from './EntradaNFEContext';
import { guiaValidation } from './validations/GuiaValidation';
import { nfValidation } from './validations/NFValidation';
import { nfItensValidation } from './validations/NFItensValidation';
import { icmsValidation } from './validations/IcmsValidation';
import { fornecedorValidation } from './validations/FornecedorValidation';
import { financeiroValidation } from './validations/FinanceiroValidation';
import {
  FormData,
  FormDataItem,
  Parcela,
  Perfil,
  Produto,
  Select2,
} from './protocols';
import {
  delZeroLeft,
  formatCurrencyAsText,
  strPadZeroL,
  transformAsCurrency,
  validarChaveNFe,
} from '~/utils/functions';
import api from '~/services/api';
import Logs from './screens/Logs';
import Search from '~/components/Search';
import { errorHandler } from '~/utils/ErrorHandler';
import moment from 'moment';
import { toast } from 'react-toastify';
import { formatPerfil } from './functions/formatacao/formatPerfil';
import { nanoid } from 'nanoid';
import { MasterDetailProps, addUpdate } from '~/utils/masterDetail';
import { BuscaNfe } from '~/components/BuscaNfe';
import { ItensProps, NfProps } from '~/components/BuscaNfe/protocols';
import { Context } from '~/components/Nfe/TransmissaoNFe/Transmissao';

import { buscaInfoTributacaoEntrada, somaCampos } from './functions/Procedures';
import Swal from 'sweetalert2';
import { TransmitirNFe } from '~/components/TransmitirNFe';
import { processarPisCofins } from './functions/Procedures/processarPisCofins';
import { createDateWithoutTimezone } from '~/utils/createDateWithoutTimezone ';

export const EntradaNFEContent: React.FC = () => {
  const { transmitirNFe } = useContext(Context);
  const [showSearch, setShowSearch] = useState(true);
  const [nfCancelada, setNfCancelada] = useState(false);

  const {
    codSeqNf,
    setCodSeqNf,
    codXml,
    setCodXml,
    setNumChaveAcesso,
    numChaveAcesso,
    setLoadingTable,
    currentTab,
    setCurrentTab,
    setCriticas,
    criticas,
    fornecedor,
    produtos,
    perfil,
    setPerfil,
    validacaoProdutor,
    serie,
    setSerie,
    loja,
    resetForms,
    setIsUpdate,
    isUpdate,
    setProdutos,
    setCanUpdate,
    canUpdate,
    formNfe,
    especies,
    perfis,
    lojas,
    changeLoja,
    changePerfil,
    changeFornecedor,
    parcelas,
    masterDetail,
    setMasterDetail,
    setParcelas,
    setXmlImportado,
    setShowFormItens,
    transmissao,
    setTransmissao,
    loadingTable,
    setBlockSerieNF,
    uuidParcela,
  } = useEntradaNFE();

  const { getValues, setValue, setError, handleSubmit, watch } = formNfe;

  async function formatedItens(itens: Produto[]) {
    let val_total_icms_fcp = 0;
    let { val_total_nf, val_total_bc_st, val_total_st_fcp, val_total_ipi } =
      getValues();
    val_total_nf = transformAsCurrency(val_total_nf);
    val_total_bc_st = transformAsCurrency(val_total_bc_st);
    val_total_st_fcp = transformAsCurrency(val_total_st_fcp);
    val_total_ipi = transformAsCurrency(val_total_ipi);

    const itensData = itens.map((produto: Produto) => {
      const fields = {
        flgIgnoraPisCofins: produto.flg_ignora_pis_cofins,
        flgNaoPisCofins: produto.flg_nao_pis_cofins,
        tipoNaoPisCofins: produto.tipo_nao_pis_cofins,
        valTotNf: val_total_nf,
        valTotSt: val_total_bc_st,
        valTotStFcp: val_total_st_fcp,
        valTotIpi: val_total_ipi,
        perAliqPis: produto.per_aliq_pis,
        perAliqCofins: produto.per_aliq_cofins,
        // valIcms: produto.val_icms_entrada,
        valIcms: produto.val_tot_icms_op,
        valPisXml: produto.val_pis_xml,
        valCofinsXml: produto.val_cofins_xml,
        valBcPisXml: produto.val_pis_xml, // descobrir de onde vem
        valBcCofinsXml: produto.val_cofins_xml, // descobrir de onde vem
        cstPisXml: produto.cst_pis_entrada,
        cstCofinsXml: produto.cst_cofins_entrada,
        cstPis: produto.cst_pis,
        cstCofins: produto.cst_cofins,
        valPis: produto.val_pis,
        valCofins: produto.val_cofins,
      };

      processarPisCofins(fields, fornecedor, loja, perfil, produto);
      val_total_icms_fcp += produto.val_tot_icms_fcp;
      return {
        cod_seq_nf_item: produto?.cod_seq_nf_item,
        cfop: produto.cfop,
        cod_gtin: produto.cod_gtin_principal,
        cod_id_ctb: produto.cod_id_ctb,
        cod_item_embalagem: 0,
        cod_ncm: produto.cod_ncm,
        cod_produto: produto.cod_produto,
        cod_sit_tributaria: produto.cod_sit_trib_entrada,
        cod_tributacao: produto.cod_trib_entrada,
        cst_cofins: produto.cst_cofins,
        cst_pis: produto.cst_pis,
        des_produto: produto.des_produto,
        des_referencia: '',
        des_unidade: produto.des_unidade, // programar
        dta_validade: '',
        flg_bonificado: produto.flg_bonificado,
        flg_guia: false,
        flg_nao_calc_bc_icms: false,
        flg_nao_pis_cofins: produto.flg_nao_pis_cofins,
        flg_ignora_pis_cofins: produto.flg_ignora_pis_cofins,
        flg_veda_cred: produto.flg_veda_cred,
        flg_veda_cred_lim: false,
        num_cest: produto.num_cest,
        num_excecao_tipi: produto.num_excecao_tipi,
        num_item: produto.num_item,
        num_ncm: produto.num_ncm,
        per_aliq_icms: produto.val_icms_entrada,
        per_cofins: produto.per_aliq_cofins,
        per_fcp_icms: produto.per_fcp_icms,
        per_fcp_st: produto.per_fcp_st,
        per_iva: 0, // verificar
        per_iva_ncm: 0,
        per_pauta_iva: 0,
        per_pauta_iva_ncm: 0,
        per_pis: produto.per_aliq_pis,
        per_red_bc_icms: produto.val_reducao_entrada,
        per_veda_cred_lim: 0,
        qtd_embalagem: produto.qtd_embalagem,
        qtd_entrada: produto.qtd_entrada,
        qtd_saida_dev: 0,
        qtd_total:
          (produto.qtd_entrada * produto.qtd_embalagem) / produto.val_peso,
        tipo_nao_pis_cofins: produto.tipo_nao_pis_cofins,
        tipo_tributacao_ent_ncm: produto.tipo_trib_entrada, // verificar
        tipo_tributacao: produto.tipo_trib_entrada,
        val_base_cofins: produto.val_base_cofins,
        val_base_ipi: produto.val_base_ipi,
        val_base_pis: produto.val_base_pis,
        val_bc_icms: produto.val_tot_bc_icms,
        val_bc_st: produto.val_tot_bc_st,
        val_bc_st_entrada: 0, // verificar
        val_bc_st_frete_dest: 0, // programar
        val_bc_st_guia: produto.val_tot_st_guia,
        val_bc_st_guia_dif: produto.val_tot_st_guia_dif,
        val_bc_st_guia_entrada: produto.val_tot_bc_st_guia_e,
        val_bc_st_ret_xml: 0,
        val_cofins: produto.val_cofins,
        val_custo_cheio: produto.val_custo_cheio,
        val_custo_medio: produto.val_custo_medio,
        val_custo_pmz: produto.val_custo_pmz,
        val_custo_rep: produto.val_custo_rep,
        val_custo_scred: produto.val_custo_scred,
        val_custo_tabela: produto.val_custo_tabela,
        val_desconto: produto.val_tot_desconto / produto.qtd_entrada,
        val_despesa_acessoria: produto.val_tot_desp_acess / produto.qtd_entrada,
        val_acrescimo: produto.val_tot_acrescimo / produto.qtd_entrada,
        val_fcp_icms: produto.val_tot_icms_fcp,
        val_fcp_st: produto.val_tot_st_fcp,
        val_frete: produto.val_tot_frete / produto.qtd_entrada,
        val_frete_dest: produto.val_tot_frete_dest,
        val_icms: produto.val_tot_icms_op, // trocar para val_tot_icms_op
        val_icms_deson: 0,
        val_icms_ent_ncm: 0,
        val_icms_fcp_xml: 0,
        val_icms_inter_dest: 0,
        val_icms_inter_origem: 0,
        val_icms_pobreza: 0,
        val_icms_ret_xml: 0,
        val_icms_st: produto.val_tot_st / produto.qtd_entrada || 0,
        val_icms_st_cred: produto.val_tot_st_cred,
        val_icms_st_frete_dest: 0, // programar
        val_icms_st_guia: produto.val_tot_st_guia,
        val_icms_st_guia_dif: produto.val_tot_st_guia_dif,
        val_icms_st_ret_xml: 0,
        val_icms_unitario: produto.val_imp_icms,
        val_imposto_credito: 0,
        val_ipi: produto.val_tot_ipi / produto.qtd_entrada,
        val_isento: produto.val_tot_isento,
        val_isento_cofins: 0, // em desuso desde 2011
        val_isento_ipi: produto.val_isento_ipi,
        val_isento_pis: 0, // em desuso desde 2011
        val_nf: produto.val_tot_nf,
        val_outros: produto.val_tot_outros_icms,
        val_outros_ipi: produto.val_outros_ipi,
        val_pauta_ipv: 0,
        val_pauta_iva: produto.val_pauta_iva,
        val_peso: produto.val_peso,
        val_pis: produto.val_pis,
        val_produto: produto.val_tot_produto,
        val_reducao_ent_ncm: produto.val_reducao_entrada_ncm,
        val_tabela: produto.val_tabela,
        val_tabela_final: produto.val_tabela_final,
        val_tabela_liq: produto.val_tabela_liq,
        val_total: produto.val_tabela_liq,
        val_unitario: produto.val_tabela,
        val_venda_varejo: produto.val_tot_venda_varejo / produto.qtd_entrada,
        val_venda_varejo_guia: produto.val_tot_vvarejo_guia,

        val_custo_devolucao: 0,
        val_custo_sicms: produto.val_custo_sicms,
        val_custo_rep_loja: produto.val_custo_rep_loja,

        //
        // per_red_bc_icms: produto.val_reducao_entrada,
        // val_icms_ent_ncm_loja: produto.val_icms_entrada,
        // val_rbc_ent_ncm_loja: produto.val_reducao_entrada,
        // tipo_trib_ent_ncm_loja: produto.tipo_trib_entrada,
        // cod_tributacao_saida: produto.cod_tributacao,
        // tipo_trib_saida: produto.tipo_tributacao,
        // per_aliq_icms_saida: produto.val_icms,
        // per_red_bc_icms_saida: produto.val_reducao,

        val_tot_icms_op: produto.val_tot_icms_op,

        // val_bc_entrada: produto.val_tot_bc_st_e,

        // val_tot_icms_op: 0, // exclusivo outras operacoes

        // val_tot_produto: produto.val_tabela_liq,

        //

        //

        //
        // cst_pis: 0,
        // cst_cofins: 0,
        val_aliq_zero_pis_cof: produto.val_aliq_zero,
        // // val_monof_pis_cof: 0, // verificar se será utilizado
        // // val_subst_pis_cof: 0, // verificar se será utilizado
        // // val_nao_tribut_pis_cof: 0, // verificar se será utilizado
        // // val_isento_pis_cof: 0, // verificar se será utilizado
        // // val_imune_pis_cof: 0, // verificar se será utilizado
        // // val_susp_pis_cof: 0, // verificar se será utilizado

        //

        // // per_item_devolvido: 0 // verificar
        //  valores XML
        val_bc_st_xml: produto.val_bc_st_xml,
        val_icms_st_xml: produto.val_icms_st_xml,
        val_bc_icms_xml: produto.val_icms_st_xml,
        val_icms_xml: produto.val_icms_xml,
        val_produto_xml: produto.val_produto_xml,
        val_despesa_acessoria_xml: produto.val_despesa_acessoria_xml,
        val_desconto_xml: produto.val_desconto_xml,
        val_fcp_st_xml: produto.val_fcp_st_xml,
        val_fcp_icms_xml: produto.val_fcp_icms_xml,
        val_pis_xml: produto.val_pis_xml,
        val_cofins_xml: produto.val_cofins_xml,
      };
    });

    return {
      itens: itensData,
      val_total_icms_fcp,
    };
  }
  const serializeFormData = async (): Promise<FormData> => {
    const {
      num_nf,
      val_total_nf,
      val_funrural,
      cod_serie,
      num_nf_produtor,
      num_serie_produtor,
      des_especie_produtor,
      num_cpf_cnpj_produtor,
      num_rg_ie_produtor,
      num_chave_produtor,
      val_total_bc,
      val_total_icms,
      val_total_bc_st,
      val_total_icms_st,
      val_total_st_fcp,
      val_total_venda_varejo,
      val_total_ipi,
      val_total_guia_st_forn,
      val_total_guia_st_sefaz,
      val_seguro,
      qtd_embalagem,
      qtd_peso_liq,
      qtd_peso_bruto,
      des_marca_veiculo,
      des_placa_veiculo,
      val_desc_financeiro,
      val_enc_financeiro,
      dta_emissao,
      dta_entrada,
      dta_emissao_produtor,
      dta_vencto_guia_st_forn,
      dta_rec_guia_st_forn,
      dta_vencto_guia_sefaz,
      des_obs_livre,
      des_obs_fiscal,
      flg_confirmar,
      des_especie,
      des_uf_produtor,
      cod_info_complementar,
    } = getValues();

    let val_total_icms_fcp = 0;

    const produtosMastedDetail: any = masterDetail.find(
      (item) => item.obj_name === 'nf_itens',
    );
    const { itens } = await formatedItens(produtos);
    let mastedDetailData: MasterDetailProps[] = [];
    if (produtosMastedDetail) {
      const insert: any = await formatedItens(
        produtosMastedDetail.itens.insert,
      );
      const update: any = await formatedItens(
        produtosMastedDetail.itens.update,
      );
      val_total_icms_fcp =
        insert.val_total_icms_fcp + update.val_total_icms_fcp;

      const newMastedDetail = {
        obj_name: 'nf_itens',
        pk_fields: ['cod_seq_nf_item'],
        itens: {
          insert: insert.itens,
          update: update.itens,
          delete: produtosMastedDetail.itens.delete,
        },
      };
      const oldMastedDetail: any[] = masterDetail.filter(
        (item) => item.obj_name !== 'nf_itens',
      );
      mastedDetailData = [...oldMastedDetail, newMastedDetail];
    }

    const formData: FormData = {
      cod_seq_nf: codSeqNf,
      cod_xml: codXml,
      cod_pessoa: fornecedor.cod_pessoa,
      flg_produtor_rural: fornecedor.flg_produtor_rural,
      num_nf,
      flg_gera_financeiro: watchPerfil.flg_gera_financeiro ?? false,
      flg_optante_simples: fornecedor.flg_optante_simples ?? false,
      tipo_emissao: perfil.tipo_emissao,
      num_serie_nf: perfil.tipo_emissao === 1 ? cod_serie.num_serie : cod_serie,
      des_especie: des_especie.des_especie,
      cod_especie: des_especie.value,
      cod_doc_fiscal: des_especie.cod_doc_fiscal,
      num_modelo_fiscal: des_especie.num_modelo_fiscal,
      tipo_operacao: perfil.tipo_operacao,
      tipo_nf: perfil.tipo_nf,
      cod_loja: loja.cod_loja,
      cod_perfil: perfil.cod_perfil,
      cod_categoria: perfil.cod_categoria,
      cod_cc: perfil.cod_cc,
      des_natureza: perfil.des_perfil.substring(0, 35),
      val_total_nf: transformAsCurrency(val_total_nf),
      dta_emissao,
      dta_entrada,
      flg_cancelada: false, // verificar
      tipo_frete: 0,
      tipo_emitente: perfil.tipo_emissao === 1 ? 0 : 1,
      des_obs_livre,
      des_obs_fiscal, // verificar
      flg_confirmar: flg_confirmar || false,
      num_chave_acesso: numChaveAcesso || getValues('num_chave_acesso'),
      val_funrural: transformAsCurrency(val_funrural),
      num_nf_produtor,
      num_serie_produtor: num_serie_produtor ?? '',
      dta_emissao_produtor,
      des_especie_produtor: des_especie_produtor.des_especie,
      des_uf_produtor: des_uf_produtor?.label ?? '',
      num_cpf_cnpj_produtor,
      num_rg_ie_produtor,
      num_chave_produtor,
      val_total_bc: transformAsCurrency(val_total_bc),
      val_total_icms: transformAsCurrency(val_total_icms),
      val_total_bc_st: transformAsCurrency(val_total_bc_st),
      val_total_icms_st: transformAsCurrency(val_total_icms_st),
      val_total_st_fcp: transformAsCurrency(val_total_st_fcp),
      val_total_venda_varejo: transformAsCurrency(val_total_venda_varejo),
      val_total_ipi: transformAsCurrency(val_total_ipi),
      val_total_guia_st_forn: transformAsCurrency(val_total_guia_st_forn),
      dta_vencto_guia_st_forn,
      dta_rec_guia_st_forn,
      val_total_guia_st_sefaz: transformAsCurrency(val_total_guia_st_sefaz),
      dta_vencto_guia_sefaz,
      val_seguro: transformAsCurrency(val_seguro),
      qtd_embalagem: qtd_embalagem ? transformAsCurrency(qtd_embalagem) : 1,
      qtd_peso_liq: transformAsCurrency(qtd_peso_liq),
      qtd_peso_bruto: transformAsCurrency(qtd_peso_bruto),
      des_marca_veiculo,
      des_placa_veiculo,
      val_desc_financeiro: transformAsCurrency(val_desc_financeiro),
      val_enc_financeiro: transformAsCurrency(val_enc_financeiro),
      val_total_icms_fcp, // verificar soma do val_tot_icms_fcp dos itens
      cod_info_complementar: cod_info_complementar?.value,
      itens,
      masterDetail: mastedDetailData,
    };

    return formData;
  };
  const watchNumNf = watch('num_nf');
  const watchPessoa = watch('cod_pessoa');
  const watchPerfil = watch('cod_perfil');
  const watchLoja = watch('cod_loja');

  const onSave = handleSubmit(
    async () => {
      if (produtos.length === 0) {
        toast.warning('Item(s) da NF deve(m) ser informado(s)');
        return;
      }

      const formData = await serializeFormData();

      const guiaValidations = guiaValidation(formData);
      const financeiroValidations = financeiroValidation(formData, parcelas);
      const nfItensValidations = nfItensValidation(formData);
      const icmsValidations = icmsValidation(formData);
      const fornecedorValidations = fornecedorValidation(fornecedor, formData);

      let validations = [];

      if (new Date(formData.dta_entrada) < new Date(formData.dta_emissao)) {
        toast.warning(
          'Data de Emissão não deve ser maior que Data de Entrada/Saída.',
        );
        return true;
      }

      if (
        moment(formData.dta_emissao_produtor).isAfter(
          moment(formData.dta_emissao),
        )
      ) {
        toast.warning(
          'Data de Emissão da NF de Produtor deve ser menor ou igual a Data de Emissao da NF.',
        );
        setError(
          'dta_emissao_produtor',
          { type: 'focus' },
          { shouldFocus: true },
        );
        setCurrentTab('produtor');
        return true;
      }

      if (formData.flg_produtor_rural) {
        if (formData.num_chave_produtor.trim() === '') {
          const informaChaveProdutor = await Swal.fire({
            title: 'Chave de Produtor não informada?',
            text: 'Deseja continuar mesmo assim?',
            showCancelButton: true,
            confirmButtonColor: '#8850BF',
            cancelButtonColor: '#DE350B',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não',
          }).then((result) => {
            if (result.isConfirmed) {
              return true;
            }
            return false;
          });

          if (!informaChaveProdutor) {
            setError(
              'num_chave_produtor',
              { type: 'focus' },
              { shouldFocus: true },
            );
            setCurrentTab('produtor');
            return;
          }
        }
        if (
          formData.num_chave_produtor !== '' &&
          formData.num_chave_produtor.length !== 44
        ) {
          toast.warning('Chave NF-e da NF de Produtor deve conter 44 dígitos.');
          setError(
            'num_chave_produtor',
            { type: 'focus' },
            { shouldFocus: true },
          );
          setCurrentTab('produtor');
          return;
        }
      }

      if (
        !(await validacaoProdutor({
          cod_pessoa: formData.cod_pessoa,
          tipo_nf: formData.tipo_nf,
          tipo_operacao: formData.tipo_operacao,
          cod_loja: formData.cod_loja,
          num_nf_produtor: formData.num_nf_produtor,
          num_serie_produtor: formData.num_serie_produtor,
          num_cnpj_cpf_produtor: formData.num_cpf_cnpj_produtor,
          des_especie_produtor: formData.des_especie_produtor,
          flg_produtor_rural: watchPessoa.flg_produtor_rural,
        }))
      ) {
        return;
      }

      // @TODO - A validar
      // if (
      //   (await validacaoProduto(produtos[0].cod_id_ctb)) &&
      //   watchPessoa.flg_produtor_rural
      // ) {
      //   const { isConfirmed } = await Swal.fire({
      //     text: 'Para nota de produtor rural, deve ser informada a retenção no cadastro de ID Contábil. Deseja continuar?',
      //     icon: 'warning',
      //     showConfirmButton: true,
      //     showCancelButton: true,
      //     confirmButtonColor: '#8850BF',
      //     cancelButtonColor: '#DE350B',
      //     confirmButtonText: 'Sim',
      //     cancelButtonText: 'Não',
      //   });
      //   if (!isConfirmed) return;
      // }
      if (watchPerfil.flg_gera_financeiro || parcelas.length > 0) {
        validations = [
          ...icmsValidations,
          ...financeiroValidations,
          ...nfItensValidations,
          ...fornecedorValidations,
          ...guiaValidations,
        ];
      } else {
        validations = [
          ...icmsValidations,
          ...nfItensValidations,
          ...fornecedorValidations,
          ...guiaValidations,
        ];
      }

      setCriticas(validations);

      if (validations.length > 0) {
        setCriticas(validations);
        setCurrentTab('critica');
        return;
      }
      if (uuidParcela) {
        const confirmaEdicaoFinanceiro = await Swal.fire({
          title:
            'Você está editando um registro na aba Financeiro. Deseja continuar sem finalizar a edição?',
          text: 'Se a edição não for concluída, o valor exibido na tabela da aba Financeiro será considerado.',
          showCancelButton: true,
          confirmButtonColor: '#8850BF',
          cancelButtonColor: '#DE350B',
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não',
        }).then((result) => {
          if (result.isConfirmed) {
            return true;
          }
          return false;
        });

        if (!confirmaEdicaoFinanceiro) {
          setCurrentTab('financeiro');
          return;
        }
      }

      const chave = formData.flg_produtor_rural
        ? formData.num_chave_produtor
        : formData.num_chave_acesso;
      const cnpjchNFe = chave.slice(6, 6 + 14);
      const fornDoc = fornecedor.num_cpf_cnpj;
      const numNFchNFe = chave.slice(25, 25 + 9);

      // if (!validarChaveNFe(chave)) {
      //   toast.warning('Chave NFe informada é inválida.');
      //   return true;
      // }
      // if (
      //   formData.des_especie.toUpperCase() === 'NFE' &&
      //   formData.tipo_emissao === 0 &&
      //   cnpjchNFe !== fornDoc.padStart(14, '0').replace(/\D/g, '')
      // ) {
      //   toast.warning(
      //     'CNPJ informado na Chave de Acesso é divergente do informado na Nota Fiscal.',
      //   );
      //   return true;
      // }

      // if (numNFchNFe !== formData.num_nf.padStart(9, '0')) {
      //   toast.warning(
      //     'Número da NF da Chave de Acesso é divergente do informado na Nota Fiscal.',
      //   );
      //   return;
      // }

      try {
        if (isUpdate && !canUpdate) {
          return;
        }

        if (!isUpdate) {
          const { data } = await api.post('/entrada-nfe', formData);
          const { message, success, data: NFData } = data;

          const { cod_seq_nf, num_nf } = NFData;
          if (success) {
            if (
              formData.tipo_emissao === 1 &&
              formData.flg_confirmar === false
            ) {
              await transmitirNFe({
                cod_loja: formData.cod_loja,
                cod_pessoa: formData.cod_pessoa,
                num_nf,
                tipo_nf: formData.tipo_nf,
                cod_seq_nf,
              });
            }
            toast.success(message);
            onNew();
          } else {
            toast.warning(message);
          }
        }
        if (isUpdate) {
          const res = await api.put(`/entrada-nfe/${codSeqNf}`, formData);
          const { message, success } = res.data;
          if (success) {
            toast.success(message);
            onNew();
          } else {
            toast.warning(message);
          }
        }
      } catch (error: any) {
        errorHandler(error);
      }
    },
    (failedValidations: any) => {
      const tabNFe = [
        'cod_perfil',
        'cod_serie',
        'des_especie',
        'dta_emissao',
        'dta_entrada',
        'val_total_nf',
      ];

      let chaveExiste = tabNFe.some((chave) => chave in failedValidations);
      if (chaveExiste) {
        setCurrentTab('nfe');
        return;
      }

      const tabProdutor = [
        'num_nf_produtor',
        'num_serie_produtor',
        'des_especie_produtor',
        'des_obs_fiscal',
        'des_uf_produtor',
        'dta_emissao_produtor',
        'num_chave_produtor',
      ];

      chaveExiste = tabProdutor.some((chave) => chave in failedValidations);
      if (chaveExiste) {
        setCurrentTab('produtor');
        return;
      }

      const tabLog = ['num_chave_acesso'];

      chaveExiste = tabLog.some((chave) => chave in failedValidations);

      if (chaveExiste) {
        setCurrentTab('logs');
      }
    },
  );

  const getNfData = async (cod_seq_nf: number) => {
    try {
      const res = await api.get(`/entrada-nfe/${cod_seq_nf}`);

      const { data } = res.data;
      return data;
    } catch (err) {
      console.error(err);
    }
  };

  const getInfoNcm = async ({
    produto_cod_ncm,
    fornecedor_des_uf,
    produto_cod_tipo_item,
  }: any) => {
    try {
      const res = await api.get('/entrada-nfe/info-ncm', {
        params: {
          cod_ncm: produto_cod_ncm,
          des_sigla: fornecedor_des_uf,
          cod_tipo_item: produto_cod_tipo_item,
        },
      });
      const { success, data, message } = res.data;
      if (!success || !data) {
        // throw new Error(message);
        return {
          per_pauta_iva_ncm: 0,
          per_fcp_st: 0,
          per_fcp_icms: 0,
        };
      }

      const { per_fcp_icms, per_fcp_st, per_pauta_iva_ncm } = data;

      return {
        per_pauta_iva_ncm,
        per_fcp_st,
        per_fcp_icms,
      };
    } catch (e: any) {
      console.error(e);
    }
  };

  async function getTributacoes() {
    try {
      const res = await api.get('/tributacao');
      const { success, data, message } = res.data;
      if (!success) {
        throw new Error(message);
      }
      const options = data.map((item: any) => {
        return {
          value: item.cod_tributacao,
          label: `${item.cod_tributacao} - ${item.des_tributacao}`,
          cod_tributacao: item.cod_tributacao,
          des_tributacao: item.des_tributacao,
          tipo_encerramento_diferido: item.tipo_encerramento_diferido,
          cod_situacao_tributaria: item.cod_situacao_tributaria,
          val_icms: item.val_icms,
          val_reducao: item.val_reducao,
          tipo_tributacao: item.tipo_tributacao,
        };
      });
      return options;
    } catch (e: any) {
      console.error(e);
      return [];
    }
  }

  const prepareItemForInsertion = useCallback(
    async (
      produto: any,
      nf_xml_item: ItensProps,
      uuid: string,
      nf: any,
      lojasfiltered: any,
      resFornecedor: any,
      tributacoes: any,
      ImportNFe: boolean,
      flg_considera_icms_op: boolean,
      flg_considera_icms_st: boolean,
      flg_optante_simples: boolean,
      cod_trib_simples: number,
    ) => {
      const ncm = await api.get(`/ncm/${produto.cod_ncm}`);
      const qtd_embalagem =
        Number(produto.qtd_embalagem) <= 0 ? 1 : Number(produto.qtd_embalagem);

      const qtd_entrada =
        transformAsCurrency(nf_xml_item.qtd_entrada) <= 0
          ? 1
          : transformAsCurrency(nf_xml_item.qtd_entrada);
      const val_peso = transformAsCurrency(nf_xml_item.val_peso || 1);
      const qtd_total = (qtd_embalagem * qtd_entrada) / val_peso;

      const val_tabela = transformAsCurrency(nf_xml_item.val_unitario);
      const val_tot_desp_acess = transformAsCurrency(
        nf_xml_item.val_despesa_acessoria,
      );
      const val_tot_acrescimo = 0;
      const val_tot_desconto = transformAsCurrency(nf_xml_item.val_desconto);
      const val_tot_frete = transformAsCurrency(nf_xml_item.val_frete);
      const val_tot_st = transformAsCurrency(nf_xml_item.val_icms_st);
      const val_tot_st_fcp = 0;
      const val_tot_venda_varejo = 0; // não sei o valor

      const val_tot_ipi = transformAsCurrency(nf_xml_item.val_ipi);

      const serializedProduto: any = {
        uuid,
        id: uuid,
        num_item: nf_xml_item.num_item,
        cod_loja: nf_xml_item.cod_loja,
        cod_id_ctb: nf_xml_item.cod_id_ctb,
        cfop: nf_xml_item.cfop,
        flg_bonificado: false,
        cod_ncm: produto.cod_ncm,
        num_ncm: ncm?.data?.data?.num_ncm,
        des_referencia: produto.des_referencia,
        cod_produto: produto.cod_produto,
        des_produto: produto.des_produto,
        cod_gtin_principal: produto.cod_gtin_principal,
        cod_gtin: produto.cod_gtin_principal,

        des_unidade_compra: produto.des_unidade_compra,
        des_unidade_venda: produto.des_unidade_venda,
        qtd_embalagem_compra: produto.qtd_embalagem_compra,
        qtd_embalagem_venda: produto.qtd_embalagem_venda,

        val_peso: produto.val_peso,
        des_unidade: nf_xml_item.des_unidade,

        qtd_embalagem,
        qtd_entrada,
        qtd_total,
        val_tabela,
        tot_tabela: nf_xml_item.val_produto_xml,

        val_tot_desp_acess,
        val_tot_acrescimo,
        val_tot_desconto,
        val_tot_frete,
        val_tot_bc_st_frete_dest: 0,
        val_frete_dest: 0,
        val_tot_frete_dest: 0,
        val_tot_venda_varejo, // Não sei
        val_tot_ipi,
        val_tot_st_fcp, // esse deve ser equivalente val_fcp_st
        val_tot_st,
        // tributacao entrada;
        cod_tributacao: produto.cod_tributacao,
        cod_trib_entrada: produto.cod_trib_entrada,
        tipo_trib_entrada: produto.tipo_tributacao,
        tipo_tributacao: produto.tipo_tributacao,
        val_reducao: transformAsCurrency(produto.val_reducao || 0),
        val_icms_entrada: transformAsCurrency(produto.val_icms || 0),
        val_reducao_entrada: transformAsCurrency(produto.val_reducao || 0),
        cod_tipo_item: 0,

        tipo_trib_entrada_produto: produto.tipo_tributacao,

        flg_custo_alterado: false,
        val_custo_rep_ant: transformAsCurrency(produto.val_custo_rep_ant || 0),
        flg_ipv: produto.flg_ipv || false,
        flg_ignora_pis_cofins: produto.flg_ignora_pis_cofins,
        flg_nao_pis_cofins: produto.flg_nao_pis_cofins,
        num_excecao_tipi: '',
        per_aliq_cofins: produto.per_aliq_cofins,
        per_aliq_pis: produto.per_aliq_pis,
        per_desp_op: transformAsCurrency(produto.per_desp_op || 0),
        per_fcp_icms: transformAsCurrency(produto.per_fcp_icms || 0),
        per_fcp_st: transformAsCurrency(produto.per_fcp_st || 0),
        per_icms_saida: transformAsCurrency(produto.per_icms_saida || 0),
        per_pauta_iva: 0, // está zerado
        per_pauta_iva_ncm: 0,

        qtd_est_atual: produto.qtd_est_atual || 0,
        tipo_nao_pis_cofins: produto.tipo_nao_pis_cofins,

        val_custo_cheio: 0, // não sei nf_fornecedor_item val_custo_cheio
        val_custo_medio_ant: transformAsCurrency(produto.val_custo_medio || 0),
        val_custo_rep_loja: transformAsCurrency(nf_xml_item.val_custo_rep || 0),
        val_custo_rep_produto: transformAsCurrency(produto.val_custo_rep || 0),
        val_custo_rep: transformAsCurrency(nf_xml_item.val_custo_rep || 0),
        val_custo_tabela: transformAsCurrency(produto.val_custo_tabela || 0),

        val_pauta_ipv: transformAsCurrency(produto.val_pauta_ipv || 0),
        val_pauta_iva: transformAsCurrency(produto.val_pauta_iva || 0),
        val_peso_produto: transformAsCurrency(produto.val_peso || 0),
        val_tot_bc_st_e: 0,
        val_tot_bc_icms: 0, // nf_fornecedor_item val_bc_icms
        val_tot_bc_st_cf: 0, // está zerado
        val_tot_bc_st_guia_dif: 0, // nf_fornecedor_item val_bc_st_guia_dif
        val_tot_bc_st_guia_s: 0, // nf_fornecedor_item val_bc_st_guia_s

        val_tot_bc_st: nf_xml_item.val_bc_st_xml, // nf_fornecedor_item val_bc_st

        val_tot_icms_fcp: 0, // nf_fornecedor_item val_icms_fcp
        val_tot_icms_op: 0, // nf_fornecedor_item.val_icms

        val_tot_isento: 0, // nf_fornecedor_item.val_isento
        val_tot_outros_icms: 0, // nf_fornecedor_item.val_outros

        val_tot_st_cf: 0, // está zerado

        val_tot_st_guia_dif: 0, // nf_fornecedor_item.val_icms_st_guia
        val_tot_st_guia: 0, // nf_fornecedor_item.val_icms_st_guia_dif
        // variaveis do XML
        val_bc_st_xml: transformAsCurrency(nf_xml_item.val_bc_st_xml || 0),
        val_icms_st_xml: transformAsCurrency(nf_xml_item.val_icms_st_xml || 0),
        val_bc_icms_xml: transformAsCurrency(nf_xml_item.val_bc_icms_xml || 0),
        val_icms_xml: transformAsCurrency(nf_xml_item.val_icms_xml || 0),
        val_produto_xml: transformAsCurrency(nf_xml_item.val_produto_xml || 0),
        val_despesa_acessoria_xml: transformAsCurrency(
          nf_xml_item.val_despesa_acessoria_xml || 0,
        ),
        val_desconto_xml: transformAsCurrency(
          nf_xml_item.val_desconto_xml || 0,
        ),
        val_fcp_st_xml: transformAsCurrency(nf_xml_item.val_fcp_st_xml || 0),
        val_fcp_icms_xml: transformAsCurrency(
          nf_xml_item.val_fcp_icms_xml || 0,
        ),
        val_pis_xml: transformAsCurrency(nf_xml_item.val_pis_xml || 0),
        val_cofins_xml: transformAsCurrency(nf_xml_item.val_cofins_xml || 0),
        vOutro: nf.vOutro,
      };

      const infoNcm = await getInfoNcm({
        produto_cod_ncm: serializedProduto.cod_ncm,
        fornecedor_des_uf: nf.des_uf,
        produto_cod_tipo_item: serializedProduto.cod_tipo_item || 0,
      });

      if (!infoNcm) return;
      const { per_pauta_iva_ncm, per_fcp_st, per_fcp_icms } = infoNcm;
      serializedProduto.per_pauta_iva_ncm = transformAsCurrency(
        per_pauta_iva_ncm || 0,
      );
      serializedProduto.per_fcp_st = transformAsCurrency(per_fcp_st || 0);
      serializedProduto.per_fcp_icms = transformAsCurrency(per_fcp_icms || 0);

      serializedProduto.val_custo_rep_loja = serializedProduto.val_custo_rep;
      serializedProduto.flg_nao_calc_bc = false;

      if (
        perfil.tipo_operacao === 1 &&
        perfil.tipo_emissao === 1 &&
        serializedProduto.tipo_trib_entrada === 3
      ) {
        serializedProduto.cod_sit_trib_entrada = '060';
      } else {
        serializedProduto.cod_sit_trib_entrada =
          produto.cod_situacao_tributaria;
      }

      let usaTribOptanteSimples = false;
      if (
        flg_optante_simples &&
        [0, 2].includes(produto.tipo_tributacao) &&
        cod_trib_simples > 0
      ) {
        usaTribOptanteSimples = true;
        serializedProduto.cod_trib_entrada = cod_trib_simples;
      }

      buscaInfoTributacaoEntrada(
        serializedProduto,
        lojasfiltered,
        resFornecedor.data.data,
        tributacoes,
        usaTribOptanteSimples
          ? cod_trib_simples
          : serializedProduto.cod_trib_entrada,
      );
      somaCampos(
        resFornecedor.data.data,
        lojasfiltered,
        serializedProduto,
        ImportNFe,
        flg_considera_icms_op,
        flg_considera_icms_st,
        true,
      );

      return serializedProduto;
    },
    [perfil.tipo_emissao, perfil.tipo_operacao],
  );

  const ValRatItemBruto = (
    ValItem: number,
    ValRat: number,
    ValTotItem: number,
  ): number => {
    return ValRat > 0 && ValTotItem > 0 ? ValItem * (ValRat / ValTotItem) : 0.0;
  };

  async function importXML(data: NfProps) {
    setLoadingTable(true);
    const { nf, itens, totais } = data;

    const { flg_considera_icms_op, flg_considera_icms_st } = nf;
    const ImportNFe = true;
    // chave
    setCodXml(nf.cod_xml);
    setNumChaveAcesso(nf.num_chave_acesso);
    // Loja
    setValue('cod_loja', { label: '', value: nf.cod_loja });
    setValue('num_chave_acesso', nf.num_chave_acesso);

    const lojasfiltered: any = lojas.find((reg) => reg.value === nf.cod_loja);

    // tributacoes
    const tributacoes = await getTributacoes();
    // Perfil
    const perfilDocumento = await api.get(`/perfil-nf-outras-operacoes/`, {
      params: {
        cod_loja: nf.cod_loja,
        cod_perfil: nf.cod_perfil,
      },
    });

    // eslint-disable-next-line no-shadow
    const perfil: any = formatPerfil(perfilDocumento.data.data);
    setPerfil(perfil);
    setValue('cod_perfil', perfil);
    // Especie
    setValue('des_especie', {
      des_doc_fiscal: 'NOTA FISCAL ELETRÔNICA',
      cod_doc_fiscal: '55',
      des_especie: 'NFE',
      label: 'NFE - NOTA FISCAL ELETRÔNICA',
      value: 33,
      num_modelo_fiscal: '',
    });

    // serie
    // perfil 179 tem o tipo_emissao = 1
    if (nf.tipo_emissao !== 1) {
      setValue('cod_serie', nf.num_serie_nf);
      setSerie({
        value: nf.num_serie_nf,
        label: nf.num_serie_nf,
        num_serie: nf.num_serie_nf,
      });
      setValue('num_nf', nf.num_nf);
      setBlockSerieNF(true);
    } else {
      setValue('num_nf', 0);
    }
    // data emissão
    const dateEmissao = moment(new Date(nf.dta_emissao)).format('YYYY-MM-DD');
    setValue('dta_emissao', dateEmissao);

    const resFornecedor = await api.get(`/fornecedor/${nf.cod_pessoa}`);
    let des_uf, flg_optante_simples, flg_produtor_rural, cod_trib_simples;

    if (resFornecedor.data.success) {
      ({ des_uf, flg_optante_simples, flg_produtor_rural, cod_trib_simples } =
        resFornecedor.data.data);

      changeFornecedor({
        ...fornecedor,
        cod_pessoa: nf.cod_pessoa,
        des_uf_produtor: { value: des_uf, label: des_uf },
        ...resFornecedor.data.data,
      });
      // Fornecedor
      setValue('cod_pessoa', {
        label: `${nf.cod_pessoa} - ${nf.des_fornecedor}`,
        value: Number(nf.cod_pessoa),
        ...fornecedor,
        cod_pessoa: nf.cod_pessoa,
        ...resFornecedor.data.data,
      });
    }
    if (flg_produtor_rural) {
      setValue('des_especie_produtor', {
        des_doc_fiscal: 'NOTA FISCAL ELETRÔNICA',
        cod_doc_fiscal: '55',
        des_especie: 'NFE',
        label: 'NFE',
        value: 33,
      });
      setValue('num_serie_produtor', nf.num_serie_nf);
      setValue('des_uf_produtor', { value: nf.des_uf, label: nf.des_uf });
      setValue('num_nf_produtor', nf.num_nf);
      setValue('dta_emissao_produtor', dateEmissao);
      setValue('num_chave_produtor', nf.num_chave_acesso);
    }

    // data entrada
    // setValue('dta_entrada', dateEmissao);

    setValue('val_total_nf', formatCurrencyAsText(totais.vNF));
    // val_total_bc
    if (totais.vBC - totais.BCICms - totais.BCICmsVdaCred < 0) {
      setValue('val_total_bc', formatCurrencyAsText(0));
    } else {
      setValue(
        'val_total_bc',
        formatCurrencyAsText(totais.vBC - totais.BCICms - totais.BCICmsVdaCred),
      );
    }
    // val_total_icms
    if (totais.vICMS - totais.VIcms - totais.VIcmsVdaCred < 0) {
      setValue('val_total_icms', 0);
    } else {
      setValue(
        'val_total_icms',
        formatCurrencyAsText(totais.vICMS - totais.VIcms - totais.VIcmsVdaCred),
      );
    }

    setValue('val_total_bc_st', formatCurrencyAsText(totais.vBCST));
    setValue('val_total_icms_st', formatCurrencyAsText(totais.vST));
    setValue('val_total_st_fcp', formatCurrencyAsText(totais.vFCPST));
    setValue('val_total_venda_varejo', formatCurrencyAsText(0));
    setValue('val_total_ipi', formatCurrencyAsText(totais.vIPI));

    setValue('des_natureza', formatCurrencyAsText(nf.natOp));
    setValue('des_obs_fiscal', nf.infCpl.substring(0, 499));

    const nfItens: any = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const nf_xml_item of itens) {
      const uuid = nanoid();

      // Busca dados completos do item
      const res = await api.get('/entrada-nfe/busca-produto', {
        params: {
          cod_fornecedor: nf.cod_pessoa,
          cod_loja: nf.cod_loja,
          cod_produto: nf_xml_item.cod_produto,
        },
      });

      const produto = res.data.data[0];
      produto.qtd_embalagem = nf_xml_item.qtd_embalagem;
      if (
        produto.tipo_movimentacao === 1 &&
        produto.flg_explosao_compra === true &&
        produto.tipo_forma_explosao === 1
      ) {
        const resExplosao = await api.get(
          '/entrada-nfe/busca-produtos-explosao',
          {
            params: {
              cod_loja: nf.cod_loja,
              cod_produto: nf_xml_item.cod_produto,
              cod_fornecedor: nf.cod_pessoa,
            },
          },
        );
        if (resExplosao.data.success && resExplosao.data.data.length > 0) {
          for (let i = 0; i < resExplosao.data.data.length; i++) {
            const produtoDecomposicao = resExplosao.data.data[i];
            produtoDecomposicao.qtd_embalagem = nf_xml_item.qtd_embalagem;

            // Criar variáveis para ValRatItemBruto
            const vValTotal: number =
              nf_xml_item.val_total * nf_xml_item.qtd_entrada;

            const vQtdKit: number =
              nf_xml_item.qtd_entrada *
              nf_xml_item.qtd_embalagem *
              Math.trunc(produtoDecomposicao.qtd_decompoe);

            const vValTabela: number =
              (vValTotal * produtoDecomposicao.per_custo_kit) / 100 / vQtdKit;
            nf_xml_item.val_unitario = vValTabela;
            // vDespAcessUn
            const val_tot_desp_acess = ValRatItemBruto(
              vValTabela * vQtdKit,
              nf_xml_item.val_despesa_acessoria_xml,
              vValTotal,
            );
            // ValItem * (ValRat / ValTotItem) : 0.0;
            // vIPIUn
            const val_ipi = ValRatItemBruto(
              vValTabela * vQtdKit,
              nf_xml_item.val_ipi,
              vValTotal,
            );
            // vSTUn
            const val_icms_st = ValRatItemBruto(
              vValTabela * vQtdKit,
              nf_xml_item.val_icms_st_xml,
              vValTotal,
            );
            // vDescontoUn
            const val_desconto = ValRatItemBruto(
              vValTabela * vQtdKit,
              nf_xml_item.val_desconto_xml,
              vValTotal,
            );
            // vDescSuframaUn
            const vDescSuframaUn = ValRatItemBruto(
              vValTabela * vQtdKit,
              0,
              vValTotal,
            );

            const novo_nf_xml_item = nf_xml_item;

            novo_nf_xml_item.val_despesa_acessoria = val_tot_desp_acess;
            novo_nf_xml_item.val_ipi = val_ipi;
            novo_nf_xml_item.val_icms_st = val_icms_st;
            novo_nf_xml_item.val_desconto = val_desconto;
            novo_nf_xml_item.val_despesa_acessoria = val_tot_desp_acess;

            // vTotOutraDespUn
            const val_despesa_acessoria_xml = ValRatItemBruto(
              vValTabela * vQtdKit,
              nf_xml_item.val_despesa_acessoria_xml,
              vValTotal,
            );
            // vBCIcmsXMLUn
            const val_bc_icms_xml = ValRatItemBruto(
              vValTabela * vQtdKit,
              nf_xml_item.val_bc_st_xml,
              vValTotal,
            );
            // vICMSXMLUn
            const val_icms_xml = ValRatItemBruto(
              vValTabela * vQtdKit,
              nf_xml_item.val_icms_xml,
              vValTotal,
            );
            // BCSTXMLUn
            const val_bc_st_xml = ValRatItemBruto(
              vValTabela * vQtdKit,
              nf_xml_item.val_bc_st_xml,
              vValTotal,
            );

            novo_nf_xml_item.val_despesa_acessoria_xml =
              val_despesa_acessoria_xml;
            novo_nf_xml_item.val_bc_icms_xml = val_bc_icms_xml;
            novo_nf_xml_item.val_icms_xml = val_icms_xml;
            novo_nf_xml_item.val_bc_st_xml = val_bc_st_xml;

            const serializedProduto = await prepareItemForInsertion(
              produtoDecomposicao,
              novo_nf_xml_item,
              uuid,
              nf,
              lojasfiltered,
              resFornecedor,
              tributacoes,
              ImportNFe,
              flg_considera_icms_op,
              flg_considera_icms_st,
              flg_optante_simples,
              cod_trib_simples,
            );
            nfItens.push(serializedProduto);
          }
        }
      } else {
        const serializedProduto = await prepareItemForInsertion(
          produto,
          nf_xml_item,
          uuid,
          nf,
          lojasfiltered,
          resFornecedor,
          tributacoes,
          ImportNFe,
          flg_considera_icms_op,
          flg_considera_icms_st,
          flg_optante_simples,
          cod_trib_simples,
        );
        nfItens.push(serializedProduto);
      }
    }
    setMasterDetail([
      {
        obj_name: 'nf_itens',
        pk_fields: ['cod_seq_nf_item'],
        itens: {
          insert: nfItens,
          update: [],
          delete: [],
        },
      },
      {
        obj_name: 'parcelas',
        pk_fields: ['cod_parcela'],
        itens: {
          insert: [],
          update: [],
          delete: [],
        },
      },
    ]);
    setProdutos(nfItens);
    setValue('xml_importado', true);
    setXmlImportado(true);
    setLoadingTable(false);
  }

  const getParcelas = async (cod_seq_nf: number) => {
    try {
      const res = await api.get(`/nf/${cod_seq_nf}/financeiro`);
      const { data, success, message } = res.data;

      if (!success) {
        throw new Error(message);
      }
      const options = data.map((item: any) => {
        const uuid = nanoid();
        return {
          uuid,
          id: uuid,
          ...item,
        };
      });
      return options;
    } catch (err) {
      console.error(err);
    }
  };

  const setValuesToForm = async (data: any, params: any) => {
    setCanUpdate(data.flg_confirmar);
    setNfCancelada(data.flg_cancelada);
    delete data.des_uf_produtor;
    if (data.cod_xml !== undefined && data.cod_xml !== null) {
      setCodXml(data.cod_xml);
    }
    setValue('cod_loja', { label: '', value: data.cod_loja });
    setValue('num_chave_acesso', data.num_chave_acesso);

    const perfilDocumento = await api.get(`/perfil-nf-outras-operacoes/`, {
      params: {
        cod_loja: data.cod_loja,
        cod_perfil: data.cod_perfil,
      },
    });

    // eslint-disable-next-line no-shadow
    const perfil: any = formatPerfil(perfilDocumento.data.data);
    setPerfil(perfil);
    setValue('cod_perfil', perfil);

    if (perfil.tipo_emissao === 1 && data.cod_seq_nf !== undefined) {
      setTransmissao(true);
    }

    setValue('des_especie', {
      des_doc_fiscal: params.des_doc_fiscal,
      cod_doc_fiscal: params.cod_doc_fiscal,
      des_especie: params.des_especie,
      label: `${params.des_especie} - ${params.des_doc_fiscal}`,
      value: params.cod_especie,
    });
    if (perfil.tipo_emissao === 1) {
      setValue('cod_serie', {
        value: data.num_serie_nf,
        label: data.num_serie_nf,
      });
    } else {
      setValue('cod_serie', data.num_serie_nf);
    }
    setValue('num_serie_produtor', data.num_serie_produtor);
    setValue('des_especie_produtor', {
      value: data.des_especie_produtor,
      label: data.des_especie_produtor,
    });
    setValue('des_uf_produtor', { value: data.des_uf, label: data.des_uf });

    setValue('cod_pessoa', {
      label: data.label_pessoa,
      value: data.cod_pessoa,
    });

    const {
      cod_pessoa,
      des_uf,
      flg_regime_st,
      flg_optante_simples,
      flg_nao_transf_icms,
      flg_pauta,
      flg_nao_desc_bc_st,
      tipo_frete,
      flg_produtor_rural,
    } = data;

    changeFornecedor({
      ...fornecedor,
      cod_pessoa,
      des_uf_produtor: { value: des_uf, label: des_uf },
      flg_regime_st,
      flg_optante_simples,
      flg_nao_transf_icms,
      flg_pauta,
      flg_nao_desc_bc_st,
      tipo_frete,
      flg_produtor_rural,
    });

    delete data.cod_pessoa;
    delete data.des_uf;
    delete data.flg_regime_st;
    delete data.flg_optante_simples;
    delete data.flg_nao_transf_icms;
    delete data.flg_pauta;
    delete data.flg_nao_desc_bc_st;
    delete data.tipo_frete;
    delete data.flg_produtor_rural;

    delete data.cod_pessoa;
    delete data.cod_loja;
    delete data.cod_perfil;
    delete data.des_especie;
    delete data.num_serie_nf;
    delete data.des_especie_produtor;
    delete data.num_serie_produtor;

    Object.keys(data).forEach((e) => {
      if (
        typeof data[e] === 'number' &&
        (e.indexOf('val') !== -1 || e.indexOf('qtd') !== -1)
      ) {
        setValue(e, formatCurrencyAsText(data[e]));
      } else if (e.indexOf('dta') !== -1 && data[e] !== null) {
        setValue(
          e,
          data[e] === null
            ? ''
            : moment(createDateWithoutTimezone(data[e])).format('YYYY-MM-DD'),
        );
      } else {
        setValue(e, data[e]);
      }
    });

    const nf_itens = data.itens.map((item: any) => {
      const uuid = nanoid();
      return {
        uuid,
        id: uuid,
        tot_tabela: item.val_tabela_liq,
        ...item,
      };
    });
    const itens_order = nf_itens.sort(
      (a: { num_item: number }, b: { num_item: number }) =>
        a.num_item - b.num_item,
    );
    addUpdate('nf_itens', itens_order, masterDetail, setMasterDetail);
    setProdutos(nf_itens);
  };

  const onRowClick = async (param: any) => {
    const { cod_seq_nf, cod_info_complementar, des_titulo } = param.row;
    setCodSeqNf(cod_seq_nf);
    const nf = await getNfData(cod_seq_nf);

    const parcelasData = await getParcelas(cod_seq_nf);

    const groupedParcels = parcelasData
      .flatMap((parcel: Parcela) => ({
        ...parcel,
        des_finalizadora: `${parcel.cod_finalizadora} - ${parcel.des_finalizadora}`,
      }))
      .reduce((accumulator: any, current: Parcela) => {
        const key = `${current.cod_condicao}-${current.cod_finalizadora}-${current.des_condicao}-${current.num_condicao}`;
        accumulator[key] = accumulator[key] || current;
        return accumulator;
      }, {});

    const uniqueParcels = Object.values(groupedParcels) as Parcela[];

    addUpdate('parcelas', uniqueParcels, masterDetail, setMasterDetail);
    setParcelas(uniqueParcels);
    setValuesToForm(nf, param.row);

    if (cod_info_complementar) {
      setValue('cod_info_complementar', {
        label: `${cod_info_complementar} - ${des_titulo}`,
        value: cod_info_complementar,
      });
    } else {
      setValue('cod_info_complementar', {
        label: 'Nenhuma',
        value: null,
      });
    }
    setCriticas([]);
    setShowFormItens('1');

    setIsUpdate(true);
    setShowSearch(false);
  };

  useEffect(() => {
    if (watchLoja && !watchLoja.label) {
      const filtered = lojas.find((reg) => reg.value === watchLoja.value);
      if (filtered) {
        changeLoja(filtered);
        setValue('cod_loja', {
          value: filtered.value,
          label: filtered.label,
          uf: filtered.uf,
          cod_loja: filtered.cod_loja,
          tipo_regime: filtered.tipo_regime,
        });
      }
    }
  }, [watchLoja]);

  const onClearForm = () => {
    setTransmissao(false);
    setNfCancelada(false);
    resetForms();
    setValue('val_total_nf', '');
    setValue('val_total_bc', '');
    setValue('val_total_icms', '');
    setValue('des_especie', '');
    setValue('cod_serie', '');
    setValue('cod_info_complementar', { label: 'Nenhuma', value: null });
  };

  const onCancel = async () => {
    onClearForm();
    setIsUpdate(false);
    setShowSearch(true);
  };
  const onNew = () => {
    onClearForm();
    setIsUpdate(false);
  };
  const onDelete = () => {
    // implementar
  };

  const onReturnSearch = () => {
    // handleClearForm();
    // handleClearFormFinalizadoraLoja();
    resetForms();
    setIsUpdate(false);
    setShowSearch(true);
    setNfCancelada(false);
    setValue('cod_info_complementar', { label: 'Nenhuma', value: null });
    // handleResetFormLoja();
  };

  return (
    <Container>
      {showSearch ? (
        <Search
          codTela={60}
          newData={() => setShowSearch(false)}
          onRowClick={onRowClick}
        />
      ) : (
        <FormDefault
          codTela={60}
          title="Entrada de NF-e de Fornecedor"
          codigoRegistro={[{ value: codSeqNf, des_campo: 'Código' }]}
          onSave={() => onSave()}
          isSave={isUpdate && !canUpdate}
          isUpdate={isUpdate}
          onCancel={() => onCancel()}
          onNew={() => onNew()}
          onDelete={() => onDelete()}
          isDelete={false}
          onClearFields={() => onClearForm()}
          onReturnSearch={() => onReturnSearch()}
          showSwitch={false}
        >
          <div className="mt-4">
            {transmissao && canUpdate === false ? (
              <TransmitirNFe codSeqNf={codSeqNf} />
            ) : (
              !isUpdate && (
                <BuscaNfe
                  codLoja={watchLoja?.value}
                  fornecedorOption={fornecedor}
                  quantityItems={produtos.length}
                  numNF={Number(watchNumNf)}
                  setNfData={importXML}
                  reset={onClearForm}
                  disabled={loadingTable}
                />
              )
            )}
            {nfCancelada && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                }}
              >
                <h3
                  style={{
                    color: 'red',
                    fontSize: '1.625rem',
                    padding: '0.3125rem',
                    lineHeight: '2.4375rem',
                    borderRadius: '0.3125rem',
                    backgroundColor: 'yellow',
                  }}
                >
                  CANCELADA
                </h3>
              </div>
            )}
            <Tabs
              id="controlled-produto"
              activeKey={currentTab}
              onSelect={(tab) => setCurrentTab(tab)}
            >
              <Tab eventKey="nfe" title="NF-e">
                <Nfe />
              </Tab>
              <Tab eventKey="produtor" title="Produtor/Exportador/Obs">
                <Produtor />
              </Tab>
              <Tab eventKey="financeiro" title="Financeiro">
                <Financeiro />
              </Tab>
              <Tab eventKey="guia" title="Guias">
                <Guia />
              </Tab>
              <Tab
                eventKey="critica"
                title={
                  <>
                    Críticas
                    {criticas.length > 0 && (
                      <Badge
                        variant="danger"
                        style={{
                          color: 'white',
                          backgroundColor: 'red',
                          marginLeft: '0.275rem',
                          borderRadius: '50%',
                        }}
                      >
                        {criticas.length}
                      </Badge>
                    )}
                  </>
                }
              >
                <Critica />
              </Tab>
              <Tab eventKey="logs" title="Logs">
                <Logs />
              </Tab>
            </Tabs>
          </div>
        </FormDefault>
      )}
    </Container>
  );
};
